<template>
  <div class="home-page flatten">
    <!-- <CreekVideoPanel /> -->
    <!-- 
    <br>x
    <br>x
    <br>x
    <br>x
    <br>x
    <br>x
    <br>x -->
    <!--
    <CreekBroadcastCard 
      v-for="broadcast in broadcasts" 
      :broadcast="broadcast" 
    />
    -->

      <div class="broadcast-panel-container">
        <!-- <h2 class="heading-label">Live Now</h2> -->
        <CreekBroadcastPanel
          :hasControls="false"
        />
      </div>

  </div>
</template>

<script>

import CreekStudio from '@creek-website-toolkit/CreekStudio.js'
import CreekBroadcastCard from '@creek-website-toolkit/components/cards/BroadcastCard'
import CreekBroadcastPanel from '@creek-website-toolkit/components/panels/BroadcastPanel'
import CreekVideoPanel from '@creek-website-toolkit/components/panels/VideoPanel'

export default {
  name: 'HomePage',
  components: {
    CreekBroadcastCard,
    CreekVideoPanel,
    CreekBroadcastPanel,
  },
  data(){ 
    return {
      broadcasts: {}
    }
  },
  computed: {
    studio() { 
      return this.$store.state.creek.studio
    }
  },
  async created(){
    this.broadcasts = await CreekStudio.get('broadcasts')
  }
}

</script>
